// VARS
:root {
  --primary: #9999cc;
  --secondary: #000000;
  --emphasis: #9999cc;
  --interactive: #9999cc;
}

//  FONT
$textFontFamily: 'Poppins', sans-serif;

//  COLORS
/* GENERAL */
$backgroundColor: #EEEEEE;
$fontColor: black;
$borderColor: rgba(0, 0, 0, 0.3);

$primaryColor: var(--primary);
$primaryTitle: white;
$primaryIcon: #eeeeee;

$secondaryColor: var(--secondary);
$secondaryTitle: white;
$secondaryIcon: #eeeeee;

$emphasis: var(--emphasis);
$interactive: var(--interactive);
$selectedText: black;

$alert: red;

$cancel: #cc3333;
$accept: #33cc33;
$warning: #F9B100;

/* CARDS */
$backgroundCards: white;
$fontCards: black;

/* TABS */
$backgroundTabs: white;
$fontTabs: black;

/* INPUTS */
$backgroundInput: white;
$fontInput: black;
$borderInput: $interactive;
$placeholderInput: rgba($fontInput, 0.7);

/* BUTTONS */
$backgroundButton: white;
$fontButton: black;
$borderButton: $interactive;
$hoverButton: white;

@mixin backgroundenfasis($percentage) {
  background-color: rgba($emphasis, $percentage);
}