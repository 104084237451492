@import "partials/variables";
@import "partials/mixins";
@import "partials/typography";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");

body {
  font-family: "Poppins", sans-serif;
  background-color: $backgroundColor;
  color: $fontColor;
}

button:focus,
input:focus {
  outline: none;
}

*:not(i) {
  font-family: $textFontFamily !important;
}

ion-header.menu {
  background-color: $primaryColor;

  ion-toolbar {
    --background: transparent;

    ion-buttons {
      &.mobile {
        display: none;
      }

      ion-menu-button,
      ion-back-button {
        color: $primaryIcon;
      }

      ion-button {
        &.search-button {
          display: none;
        }

        .icon,
        ion-icon {
          color: $primaryIcon;
          font-size: 27px;

          &.icon-cancel-circle {
            color: $cancel !important;
          }
        }
      }
    }

    ion-title {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: left;
      @include principal6($primaryTitle);
      padding-bottom: 0;
      padding-top: 0;
      padding-inline-start: 60px !important;
    }
  }
}

ion-content {
  --ion-background-color: #eeeeee;
  color: $fontColor;
}

.popover {
  --backdrop-opacity: 0.4;
  --height: auto;
  --width: 350px;

  .popover-content {
    top: 20% !important;
    position: static !important;
    background: white !important;
    border-radius: 20px !important;
    overflow-y: hidden;
  }

  ion-header.popover {
    height: 40px;
    background: #1d1d1b;
    display: flex;
  
    ion-title {
      text-align: center;
      @include principal5(white);
      margin: auto;
    }
  }
  
  .popover-content {
    margin: auto;
    position: relative !important;
    left: 0 !important;

    // &.min-height {
    //   min-height: 500px;
    // }
  
    .buttons-container {
      position: absolute;
      display: flex;
      justify-content: space-around;
      bottom: 0;
      width: 100%;
      background-color: white;
    }
  }
}

.qr-container {
  display: flex;
  justify-content: center;
}

.contextmenu {
  --width: 270px;

  .popover-content {
    border-radius: 16px !important;
  }
}

.loading,
.no-content {
  text-align: center;
  padding: 0;
  display: grid;
  height: 100px;
  @include subtitulo1(rgba($fontColor, 0.5));
}

ion-toolbar.searchbar-container {
  width: 100%;
  position: relative;
  --background: transparent;
  --border-width: 0;

  ion-searchbar {
    padding: 12px 8px;
    --background: white;
    transition: all linear 0.5s;
    position: absolute;
    top: 0;
    left: 0;

    &.hide {
      height: 0;
      width: 0;
      top: -200px;
      left: 200px;
    }
  }
}