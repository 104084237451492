@import "variables";

custom-button {
  width: 100%;
}

@mixin boton(
  $height,
  $grosorborde,
  $colorborde,
  $colorfondo,
  $colortexto,
  $sizeicono,
  $colortextohover
) {
  height: ($height + 16px);
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  display: flex;

  &:hover {
    div {
      background: $colorborde;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.42);
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.42);

      ion-label,
      ion-title {
        color: $colortextohover;
      }

      .icon {
        color: $colortextohover !important;
      }
    }

    &:disabled,
    &.disabled {
      div {
        background-color: $colorfondo;
        box-shadow: none;
        -webkit-box-shadow: none;

        ion-label,
        ion-title {
          color: $colortexto;
          cursor: default;
        }

        .icon {
          color: $colorborde !important;
        }
      }
    }
  }

  &:active {
    div {
      background: $colorborde;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  &:disabled {
    opacity: 56%;
    pointer-events: none;
    cursor: default;
  }

  &.disabled {
    cursor: default;
    opacity: 56%;
  }

  div {
    transition: background 0.5s;
    position: relative;
    height: $height;
    width: 100%;
    min-width: calc(100% - 76px);
    border: $grosorborde solid $colorborde;
    background-color: $colorfondo;
    border-radius: 25px;
    margin: 8px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ripple-parent {
      position: relative;
      overflow: hidden;
    }

    ion-label,
    ion-title {
      @include boton2($colortexto);
      transition: color 0.5s;
      text-transform: uppercase;
      text-align: center;
      max-width: 100%;
      padding: 0;
      display: inline-grid;
      height: 14px;
      font-size: 16px;
      font-weight: bold;
      margin: auto 0;

      &.ios {
        padding: 0 25px;
      }
    }

    .icon {
      position: absolute;
      font-size: $sizeicono;
      transition: color 0.5s;

      &.left {
        color: $colorborde;
        left: 3px;
      }

      &.right {
        color: $colortexto;
        right: 3px;
      }
    }
  }
}

@mixin toggle($iconoFalse, $colorFalse, $iconoTrue, $colorTrue) {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: auto;
  background: white;
  border-radius: 50%;
  border: 2px solid $interactive;
  z-index: 0;
  --background: transparent;
  --background-checked: transparent;
  --handle-box-shadow: none;
  --handle-spacing: 0;
  --handle-height: 30px;
  --handle-width: 30px;

  --handle-background: no-repeat center/80%
    url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23010001;%7D .st1%7Bfill:none;%7D .st2%7Bfill:%23FFFFFF;%7D .st3%7Bfill:%23E43D30;%7D%0A%3C/style%3E%3Cpath class='st3' d='M17,10l-1,0l0-2.67C16,5.49,13.84,4,12,4S8,5.49,8,7.33L8,10l-1,0c-1.1,0-2,0.9-2,2l0,6c0,1.1,0.9,2,2,2l10,0 c1.1,0,2-0.9,2-2l0-6C19,10.9,18.1,10,17,10z M10,7.33C10,6.23,10.9,6,12,6c1.1,0,2,0.23,2,1.33L14,10l-4,0L10,7.33z'/%3E%3C/svg%3E%0A");
  --handle-background-checked: no-repeat center/80%
    url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23010001;%7D .st1%7Bfill:none;%7D .st2%7Bfill:%23FFFFFF;%7D %0A%3C/style%3E%3Cpath class='st2' d='M17,10h-7V7.33C10,6.23,10.9,6,12,6c1.1,0,2,0.23,2,1.33h2C16,5.49,13.84,4,12,4c-1.84,0-4,1.49-4,3.33V10H7 c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2l10,0c1.1,0,2-0.9,2-2v-6C19,10.9,18.1,10,17,10z'/%3E%3C/svg%3E%0A");

  &.toggle-checked {
    background: $interactive;
  }
}
